<template>
    <div class="h100">
        <van-loading class="loading-item" v-if="loading" type="spinner" size="24px" text-size="16px" color="#fe5e30" vertical>加载中...</van-loading>
        <template v-else>
            <dl class="earnings">
                <dt>
                    <em>总收益(元)</em>
                    <strong>{{info.totalAmountStr}}</strong>
                </dt>
                <dd class="flex">
                    <div class="dd-child">
                        <em>已结算收益</em>
                        <strong>{{info.cashedAmountStr}}</strong>
                    </div>
                    <div class="dd-child">
                        <em>未结算收益</em>
                        <strong>{{info.cashingAmountStr}}</strong>
                    </div>
                </dd>
            </dl>
            <ul class="link-list clearfix">
                <li @click="goNext('ReturnsDetailed')">
                    <img src="../../assets/img/index_icon1.png"/><span>收益明细</span>
                </li>
                <li @click="goNext('BillingDetails')">
                    <img src="../../assets/img/index_icon2.png"/><span>结算明细</span>
                </li>
                <li @click="goNext('Case')">
                    <img src="../../assets/img/index_icon3.png"/><span>案例展示</span>
                </li>
                <li @click="goNext('Regular')">
                    <img src="../../assets/img/index_icon4.png"/><span>分销规则</span>
                </li>
                <li @click="goNext('Personal')">
                    <img src="../../assets/img/index_icon5.png"/><span>个人中心</span>
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
    import {offer} from "../../assets/api/commission";

    export default {
        data() {
            return {
                loading: true,
                info: {}
            }
        },
        created() {
            this.getOffer()
        },
        methods: {
            goNext(pathName) {
                this.$router.push({name: pathName})
            },
            getOffer() {
                offer(this.query).then(res => {
                    this.info = res.data
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .h100 {
        height: 100%;
        box-sizing: border-box;
    }

    .earnings {
        width: 100%;
        height: 392px;
        padding: 58px 32px 0;
        box-sizing: border-box;
        background: #fe5e30 url("../../assets/img/BJT@2x.png") center no-repeat;
        background-size: 100% 100%;
        color: #fff;

        em {
            font-size: @font-size0;
            margin-bottom: 32px;
        }

        strong, em {
            display: block;
            line-height: 1;
        }

        dt strong {
            font-size: 72px;
        }

        dd {
            margin-top: 72px;

            .dd-child {
                width: 50%;
            }

            em {
                margin-bottom: 24px;
            }

            strong {
                font-size: 64px;
            }
        }
    }

    .link-list {
        margin-top: 16px;
        padding: 32px 32px 0;
        height: calc(100% - 392px - 16px);
        background: #fff;
        box-sizing: border-box;

        li {
            float: left;
            width: 47%;
            height: 120px;
            border-radius: 10px;
            box-sizing: border-box;
            border: solid 2px #fe5e30;
            margin-bottom: 40px;
            .flex;
            .align-items-center;
            .justify-content-center;
        }

        li:nth-child(2), li:nth-child(4) {
            margin-left: 6%;
        }

        img {
            height: 52px;
        }

        span {
            color: @color-fe5e30;
            font-size: @font-size6;
            margin-left: 26px;
        }
    }
</style>