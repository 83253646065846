import request from '../../utils/request'

// 收益明细
export function returns(data) {
    return request({
        url: '/commission/orders',
        method: 'get',
        params: data
    })
}

// 结算明细
export function billing(data) {
    return request({
        url: '/commission/index',
        method: 'get',
        params: data
    })
}

// 佣金统计
export function offer(data) {
    return request({
        url: '/commission/offer',
        method: 'get',
        params: data
    })
}